import Axios from 'axios';
import { getTimestampAndISOTime } from 'helpers/datetime';
import { isProduction } from 'lib/environment';
import { createDocRef, getDocument, setDocument } from 'lib/firebase/utils';
import queryString from 'query-string';
import { UserSummary } from 'services/groups';
import {
  GetShopProductPublisherParams,
  PriceRange,
  ShopProductFilter,
  ShopProductRelation,
  ShopProductSearchFilter,
  ShopProductSummary
} from './interface';

interface SearchShopProductsResponse {
  item_summary: {
    type: 'SHOP_PRODUCT';
    shop_product_summary?: ShopProductSummary;
  };
}

export const searchShopProducts = async (filter: ShopProductSearchFilter) => {
  try {
    const query = queryString.stringify(filter, { arrayFormat: 'comma' });

    const response = await Axios.get<SearchShopProductsResponse[]>(
      `${process.env.REACT_APP_SEARCH_API_HOST}/api/v1/shop-products/search?${query}`
    );

    if (response.status === 200) return response.data;

    return null;
  } catch (error) {
    return null;
  }
};

interface GetShopProductsResponse {
  item_summary: {
    shop_product_summary: ShopProductSummary;
  };
  metadata: {
    tags: string[];
  };
}

export const getShopProducts = async (filter: ShopProductFilter) => {
  try {
    const query = queryString.stringify(filter, { arrayFormat: 'comma' });

    const response = await Axios.get<GetShopProductsResponse[]>(
      `${process.env.REACT_APP_FILTER_API_HOST}/api/v1/shop-products/filter?${query}`
    );

    if (response.status === 200)
      return {
        data: response.data.map(item => item.item_summary.shop_product_summary),
        metadata: response.data.map(item => item.metadata),
        totalCount: Number(response.headers['x-total-count'])
      };

    return null;
  } catch (error) {
    return null;
  }
};

export const getShopProductsPriceHistogram = async (
  filter: ShopProductFilter
) => {
  try {
    const query = queryString.stringify(filter, { arrayFormat: 'comma' });

    const response = await Axios.get<PriceRange[]>(
      `${process.env.REACT_APP_FILTER_API_HOST}/api/v1/shop-products/price-histogram?${query}`
    );

    if (response.status === 200) return response.data;

    return null;
  } catch (error) {
    return null;
  }
};

export interface ShopProductStatsItem {
  tag: string;
  count: number;
}

export interface ShopProductStatsResponse {
  categories: ShopProductStatsItem[];
  amenities: ShopProductStatsItem[];
  languages: ShopProductStatsItem[];
  diets: ShopProductStatsItem[];
  meals: ShopProductStatsItem[];
  atmospheres: ShopProductStatsItem[];
}

export const getShopProductStats = async (params: ShopProductFilter) => {
  try {
    const query = queryString.stringify(params, { arrayFormat: 'comma' });

    const response = await Axios.get<ShopProductStatsResponse>(
      `${process.env.REACT_APP_FILTER_API_HOST}/api/v1/shop-products/stats?${query}`
    );

    if (response.status === 200) return response.data;

    return null;
  } catch (error) {
    return null;
  }
};

interface GetAccommodationStatsResponse {
  product_id: string;
  option_id: string;
  number_of_tickets_for_sale: number;
  number_of_sales: number;
}

export const getAccommodationStats = async (productId: string) => {
  try {
    const response = await Axios.get<GetAccommodationStatsResponse[]>(
      `${process.env.REACT_APP_MARKETPLACE_API_HOST}/api/v1/shop_products/${productId}/retreat/accommodation-options/stats`,
      {
        timeout: 10000
      }
    );

    if (response.status === 200) return response.data;

    return null;
  } catch {
    return null;
  }
};

interface GetShopProductDetailsBySlugParams {
  slug: string;
  params: {
    include_pending?: boolean;
  };
}

export const getShopProductDetailsBySlug = async ({
  slug,
  params
}: GetShopProductDetailsBySlugParams) => {
  try {
    const query = queryString.stringify(params, { arrayFormat: 'comma' });

    const response = await Axios.get<ShopProductSummary>(
      `${process.env.REACT_APP_FILTER_API_HOST}/api/v1/shop-products/${slug}/slug?${query}`
    );

    if (response.status === 200) return response.data;

    return null;
  } catch (error) {
    return null;
  }
};

interface GetShopProductPublisherResponse {
  item_summary: {
    user_summary: UserSummary;
  };
}
export const getShopProductPublishers = async (
  params: GetShopProductPublisherParams
) => {
  try {
    const query = queryString.stringify(params, { arrayFormat: 'comma' });

    const response = await Axios.get<GetShopProductPublisherResponse[]>(
      `${process.env.REACT_APP_FILTER_API_HOST}/api/v1/shop-products/publishers?${query}`
    );

    return response.data.map(item => item.item_summary.user_summary);
  } catch {
    return null;
  }
};

export const setIntendedToBuyProduct = async (
  userId: string,
  shopProduct: ShopProductSummary
) => {
  if (!isProduction) console.log('userId', userId);
  const shopProductRelationRef = createDocRef(
    `/users/${userId}/shop_product_relation/${shopProduct.id}`
  );

  getDocument(shopProductRelationRef).then(doc => {
    const timestamp = getTimestampAndISOTime();

    const data = {
      is_intending_to_buy: true,
      intended_to_buy_at: timestamp,
      shop_product_summary: {
        id: shopProduct.id,
        owner_id: shopProduct.owner.id,
        type: shopProduct.type
      },
      created_at: timestamp,
      updated_at: timestamp
    };

    if (!doc.exists()) {
      return setDocument(shopProductRelationRef, data, { merge: true });
    }

    const shopProductRelation = doc.data() as ShopProductRelation;

    if (!shopProductRelation.is_intending_to_buy) {
      return setDocument(shopProductRelationRef, data, { merge: true });
    }

    return null;
  });
};

interface GuestDetail {
  firstName: string;
  lastName: string;
  email?: string;
}

export const setGuestDetailsOnShopProductRelation = (
  userId: string,
  shopProductId: string,
  guestDeatils: GuestDetail[]
) => {
  const shopProductRelationRef = createDocRef(
    `/users/${userId}/shop_product_relation/${shopProductId}`
  );

  return getDocument(shopProductRelationRef).then(doc => {
    if (!doc.exists()) {
      return null;
    }

    const data = {
      guest_details: guestDeatils.map(guest => ({
        first_name: guest.firstName,
        last_name: guest.lastName,
        ...(guest.email ? { email: guest.email } : {})
      }))
    };

    return setDocument(shopProductRelationRef, data, { merge: true });
  });
};
